import React from "react";
import { graphql } from "gatsby";
import md from "markdown-it";

import Layout from "../components/layout";
import SEO from "../components/seo";
import resume from "../../content/resume/resume.json";

class Resume extends React.Component {
    componentDidMount() {
        var elements = document.querySelectorAll(".resume-heading-details div")
        for (var i = 0; i < elements.length; i++) {
            elements[i].innerHTML = elements[i].innerHTML.replace(/\b([a-zA-Z])([a-zA-Z]+)?\b/gim, "<span class='first-letter'>$1</span>$2");
        }

        document.querySelector(".skills thead").remove();
        document.querySelectorAll(".projects-undertaken thead").forEach(node => node.remove());
    }

    render() {
        const { data } = this.props;

        const siteTitle = data.site.siteMetadata.title;

        let workingSince = new Date(resume.workingSince);
        let dateDiff = new Date() - workingSince;

        let years = Math.floor(dateDiff / (525600 * 60 * 1000));
        let months = Math.floor((dateDiff / (525600 * 60 * 1000) - years) * 12);
        let experience = `${years} years ${months} months`;

        let dateOfBirth = new Date(resume.dob);

        let mdInstance = md({
            html: true,
            typographer: true,
            breaks: true,
            linkify: true
        });

        let website = resume.website.split(",").map((website) => {
            website =website.trim();
            return `<a href=//${website} target='blank'>${website}</a>`;
        }).join(", ")

        let professionalSummary = mdInstance.render(resume.professionalSummary);

        let educationalQualifications = mdInstance.render(resume.educationalQualifications);

        let skills = mdInstance.render(resume.skills);

        let projects = resume.projects.map((project) => {
            project.projectDetails = mdInstance.render(project.projectDetails);
            return project;
        })

        let personalProjects = projects.filter(project => project.isPersonalProject === true);
        let professionalProjects = projects.filter(project => project.isPersonalProject !== true);

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO title="Resume" />
                <article className="resume-content container">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1">
                            <h1>RESUME</h1>
                            <br />
                            <div className="my-name">{resume.name}</div>
                            <div className="resume-heading-details">
                                <div>{resume.qualification}</div>
                                <div>{`Email: ${resume.email}`}</div>
                                <div>{resume.qualifyingInstitute}</div>
                                <div>{`Contact No: ${resume.contactNumber}`}</div>
                            </div>

                            <div className="personal-details">
                                <h2>{resume.personalDetailsHeading}</h2>
                                <div className="personal-detail">
                                    <span>Name</span><span>{resume.name}</span>
                                </div>
                                <div className="personal-detail">
                                    <span>Email</span><span>{resume.email}</span>
                                </div>
                                <div className="personal-detail">
                                    <span>Total Experience</span><span>{experience}</span>
                                </div>
                                <div className="personal-detail">
                                    <span>Date of Birth</span><span>{dateOfBirth.toDateString()}</span>
                                </div>
                                <div className="personal-detail">
                                    <span>Languages known</span><span>{resume.languages.join(", ")}</span>
                                </div>
                                <div className="personal-detail">
                                    <span>Permanent address</span><span>{resume.permanentAddress}</span>
                                </div>
                                <div className="personal-detail">
                                    <span>Website</span><span dangerouslySetInnerHTML={{ __html: website }}></span>
                                </div>
                            </div>

                            <div className="professional-summary">
                                <h2>{resume.professionalSummaryHeading}</h2>
                                <div dangerouslySetInnerHTML={{ __html: professionalSummary }} />
                            </div>

                            <div className="educational-qualifications">
                                <h2>{resume.educationalQualificationHeading}</h2>
                                <div dangerouslySetInnerHTML={{ __html: educationalQualifications }} />
                            </div>

                            <div className="skills">
                                <h2>{resume.skillsHeading}</h2>
                                <div dangerouslySetInnerHTML={{ __html: skills }} />
                            </div>

                            <div className="projects-undertaken print-page-break-before">
                                <h2>{resume.projectsUndertakenHeading}</h2>
                                <h3>Professional Projects</h3>

                                {
                                    professionalProjects.map((project) => {
                                        return (
                                            <div class="project">
                                                <h4>{project.projectTitle}</h4>
                                                <div dangerouslySetInnerHTML={{ __html: project.projectDetails }} />
                                            </div>
                                        )
                                    })
                                }

                                <h3 className="print-page-break-before">Personal/Educational/Hobby Projects</h3>

                                {
                                    personalProjects.map((project) => {
                                        return (
                                            <div class="project">
                                                <h4>{project.projectTitle}</h4>
                                                <div dangerouslySetInnerHTML={{ __html: project.projectDetails }} />
                                            </div>
                                        )
                                    })
                                }

                            </div>

                        </div>
                    </div>
                    <br />
                    <br />

                </article>
            </Layout>
        )
    }
}

export default Resume

export const pageQuery = graphql`
  query{
    site {
      siteMetadata {
        title
      }
    }
  }
`
